import { toPercentString } from '@/libs/common.js'

export function prepareDevs(devObj, weldTime) {
  return {
    id: 1,
    name: 'Общее сводное: ' + toPercentString(
      devObj.devCommon ? devObj.devCommon : devObj.common, 
      weldTime
    ),
    children: [
      {
        id: 2,            
        name: 'Допустимое сводное: ' + toPercentString(
          devObj.devAllow ? devObj.devAllow : devObj.allow, 
          weldTime
        ),
        children: [
          {
            id: 3,
            name: 'Допустимое вверх: ' + toPercentString(
              devObj.devAllowUp ? devObj.devAllowUp : devObj.allowUp, 
              weldTime
            )
          },
          {
            id: 4,
            name: 'Допустимое вниз: ' + toPercentString(
              devObj.devAllowDown ? devObj.devAllowDown : devObj.allowDown, 
              weldTime
            )
          }
        ]
      },
      {
        id: 5,
        name: 'Критическое сводное: ' + toPercentString(
          devObj.devCrit ? devObj.devCrit : devObj.crit, 
          weldTime
        ),
        children: [
          {
            id: 6,
            name: 'Критическое вверх: ' + toPercentString(
              devObj.devCritUp ? devObj.devCritUp : devObj.critUp, 
              weldTime
            )
          },
          {
            id: 7,
            name: 'Критическое вниз: ' + toPercentString(
              devObj.devCritDown ? devObj.devCritDown : devObj.critDown, 
              weldTime
            )
          }
        ]
      }
    ]
  }      
}